<template>
  <aside class="main-sidebar">
    <!-- sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel -->
      <div class="user-panel" v-if="workspace">
        <div class="info">
          <h6 class="text-bold">{{ workspace.name }}</h6>
          <router-link class="ml-2 mr-2" :to="{name: 'workspace'}">
            <i class="fa-solid fa-rotate"></i>
          </router-link>

          <router-link  class="ml-2 mr-2" :to="{name: 'settings_account'}">
            <i class="fa-solid fa-gear"></i>
          </router-link>

          <a class="ml-2 mr-2" href="#" @click.prevent="logout">
            <i class="fa-solid fa-right-from-bracket"></i>
          </a>
        </div>
      </div>

      <!-- sidebar menu -->
      <ul class="sidebar-menu" data-widget="tree">
        <li :class="{'active': $route.name === 'dashboard'}">
          <router-link :to="{name: 'dashboard'}">
            <i class="fa-solid fa-table-cells-large mr-2"></i> <span>Dashboard</span>
          </router-link>
        </li>
        <li>
          <a href="index-classic.html">
            <i class="fa-solid fa-paper-plane mr-2"></i> <span>Request <span class="badge badge-danger badge-pill float-lg-right">14</span></span>
          </a>
        </li>
        <li>
          <a href="index-classic.html">
            <i class="fa-solid fa-user mr-2"></i> <span>Customer</span>
          </a>
        </li>
        <li>
          <a href="index-classic.html">
            <i class="fa-solid fa-calendar mr-2"></i> <span>Booking</span>
          </a>
        </li>
        <li>
          <a href="index-classic.html">
            <i class="fa-solid fa-wallet mr-2"></i> <span>Payment</span>
          </a>
        </li>
        <li>
          <a href="index-classic.html">
            <i class="fa-solid fa-users mr-2"></i> <span>Teams</span>
          </a>
        </li>
        <li>
          <a href="index-classic.html">
            <i class="fa-solid fa-chart-simple mr-2"></i> <span>Analytics</span>
          </a>
        </li>
        <li :class="{'active': $route.name !== undefined && $route.name.includes('settings_')}">
          <router-link :to="{name: 'settings_account'}">
            <i class="fa-solid fa-gear mr-2"></i> <span>Settings</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      workspace: 'auth/getWorkspace'
    })
  }
}
</script>