import auth from "@/middlewares/auth";
import workspace from "@/middlewares/workspace";
export const settingsRoutes = [
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Template.vue'),
    children: [
      {
        path: 'account',
        name: 'settings_account',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/account/Index.vue'),
        meta: {
          middleware: [
            auth, workspace
          ]
        }
      },
      {
        path: 'members',
        name: 'settings_members',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/members/Index.vue'),
        meta: {
          middleware: [
            auth, workspace
          ]
        }
      },
      {
        path: 'items',
        name: 'settings_items',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/items/Index.vue'),
        meta: {
          middleware: [
            auth, workspace
          ]
        }
      },
      {
        path: 'notification',
        name: 'settings_notification',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/notification/Index.vue'),
        meta: {
          middleware: [
            auth, workspace
          ]
        }
      },
      {
        path: 'billing',
        name: 'settings_billing',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/billing/Index.vue'),
        meta: {
          middleware: [
            auth, workspace
          ]
        }
      },
      {
        path: 'security',
        name: 'settings_security',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/security/Index.vue'),
        meta: {
          middleware: [
            auth, workspace
          ]
        }
      },
    ]
  }
];