import guest from "@/middlewares/guest";
import auth from "@/middlewares/auth";
import workspace from "@/middlewares/workspace";
import {settingsRoutes} from "@/routes/routes/settingsRoutes";

let routes = [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login'),
    name: 'login',
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/signup',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Signup.vue'),
    name: 'signup',
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/workspace',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Workspace.vue'),
    name: 'workspace',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "auth" */ '../views/dashboard/Index.vue'),
    name: 'dashboard',
    meta: {
      middleware: [
        auth, workspace
      ]
    }
  },
]

routes.push(...settingsRoutes);

export default routes;
