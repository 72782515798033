export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
        .finally(() => {
          this.$router.push({name: 'login'})
        })
    },
    openInNewTab(payload) {
      const routeData = this.$router.resolve(payload);
      window.open(routeData.href, '_blank');
    },
    async download(url, filename) {
      const file = await fetch(url);

      const blob = await file.blob();

      const blobUrl = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      downloadLink.download = filename;

      downloadLink.click();
    },
    showSuccessMsg(msg) {
      this.$swal.fire({
        position: 'top-right',
        icon: 'success',
        toast: true,
        title: msg,
        showConfirmButton: false,
        timer: 1500
      })
    },
    showFailMsg(msg) {
      this.$swal.fire({
        position: 'top-right',
        icon: 'error',
        toast: true,
        title: msg,
        showConfirmButton: false,
        timer: 1500
      })
    },
    pageChanged(page) {
      this.queryParams.page = page;
      this.loadData();
    },
    sorting({column, order}) {
      this.queryParams.sort = column;
      this.queryParams.sort_order = order;
      this.queryParams.page = 1;
      this.loadData();
    },
    filter(key, value) {
      this.queryParams.page = 1;
      this.queryParams[key] = value;
      this.loadData();
    },
    search({q, columns}) {
      this.queryParams.page = 1;
      this.queryParams.search = q;
      this.queryParams.search_columns = columns;
      this.loadData();
    },
    searchText(q) {
      let columns = this.columns.filter((col) => col.searchable).map(col => col.field);

      this.queryParams.page = 1;
      this.queryParams.search = q;
      this.queryParams.search_columns = columns.join(',');

      this.loadData();
    },
    refreshData() {
      this.queryParams.page = 1;

      this.loadData();
    },
    getFirstWeekDay(date, dayOfWeek) {
      let day = date.day();
      let diffDays = 0;

      if (day > dayOfWeek) {
        diffDays = 7 - (day - dayOfWeek);
      } else {
        diffDays = dayOfWeek - day
      }

      return date.add(diffDays, 'day');
    }
  },
}