<template>
  <div class="wrapper boxed-wrapper" v-if="!authRoutes.includes($route.name)">
    <teleport to="body">
      <header-component />
      <side-bar-component />

      <div class="content-wrapper">
        <router-view />
      </div>

      <footer-component />
    </teleport>
  </div>
  <router-view v-else />
</template>

<script>

import HeaderComponent from "@/components/layouts/HeaderComponent.vue";
import SideBarComponent from "@/components/layouts/SideBarComponent.vue";
import FooterComponent from "@/components/layouts/FooterComponent.vue";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {FooterComponent, SideBarComponent, HeaderComponent},
  data() {
    return {
      authRoutes: ['signup', 'login', 'workspace']
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
  async created() {
    if (this.user) {
      await this.$store.dispatch('auth/checkUser')
          .catch(() => {
            this.$router.push({name: 'login'})
          });
    }
  },
  watch: {
    $route() {
      if (this.authRoutes.includes(this.$route.name)) {
        document.body.classList.add('login-page', 'sty1')
        document.body.classList.remove('skin-blue', 'sidebar-mini')
      } else {
        document.body.classList.add('skin-blue', 'sidebar-mini')
        document.body.classList.remove('login-page', 'sty1')
      }
    }
  }
}
</script>
