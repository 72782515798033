<template>
  <template v-if="errors && errors.length">

  </template>
  <div class="help-block" v-for="(err, index) in errors" :key="key(index)">
    <span class="text-danger">{{ err }}</span>
  </div>
</template>

<script>
export default {
  props: ['errors'],
  methods: {
    key(index) {
      return `validation_error_${index}_${Math.random()}`
    }
  }
}
</script>