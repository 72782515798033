import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      user: JSON.parse(localStorage.getItem('user'))|| null,
      workspace: JSON.parse(localStorage.getItem('workspace'))|| null,
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setWorkspace(state, payload) {
      state.workspace = payload;
    },
  },
  actions: {
    register({dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/register', payload).then((response) => {
          let user = response.data.data;
          dispatch('setUser', user);

          resolve(user);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    checkUser({state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get('/user', {
          headers: {
            'Authorization': `Bearer ${state.user.token}`
          }
        }).then(() => {
          resolve(true);
        }).catch((error) => {
          dispatch('resetUser');
          reject(error.response.data)
        });
      });
    },
    login({dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/login', payload).then((response) => {
          let user = response.data.data;
          dispatch('setUser', user);

          resolve(user);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    socialLogin({dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/social-login', payload).then((response) => {
          let user = response.data.data;
          dispatch('setUser', user);

          resolve(user);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    logout({dispatch}) {
      return new Promise( (resolve) => {
        axios.post('/logout')
          .finally(() => {
            dispatch('resetUser');

            resolve(true);
          });
      });
    },
    setUser({commit}, user) {
      commit('setUser', user);
      localStorage.setItem('user', JSON.stringify(user));

      axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    },
    selectWorkspace({commit}, workspace) {
      commit('setWorkspace', workspace);
      localStorage.setItem('workspace', JSON.stringify(workspace));

      axios.defaults.headers.common['workspace'] = workspace.id;
    },
    resetUser({commit}) {
      commit('setUser', null);
      commit('setWorkspace', null);
      localStorage.removeItem('user');
      localStorage.removeItem('workspace');
      axios.defaults.headers.common['Authorization'] = null;
    }
  },
  getters: {
    getUser: (state) => state.user,
    getWorkspace: (state) => state.workspace,
  }
}